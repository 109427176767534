import { graphql, useStaticQuery } from "gatsby"
import React, { useContext } from "react"
import { PageContext } from "../../context/PageContext"
import ProductFilter, { ProductFilterProduct } from "../organisms/product/ProductFilter"
//import * as styles from "./StaticDatoProductFilter.module.scss"

type StaticProduct = {
  identifier: string
  locale: string
  name: string
  categories: string[]
  product_family: string
}

export type StaticDatoProductFilterProps = { title?: string; description?: string; params?: Record<string, string> }

/**
 * Haetaan staattisesta tuotedatasta tuotteet, mikäli hakuparametri täsmää näihin mahdollisiin:
 *
 * product_family, sku, categories
 *
 * HUOM. Käytetään tätä vain SSR tilassa loadable komponentin kautta, jotta selaimen ei tarvitse ladata näitä turhaan.
 * Datan määrä voi olla useita megoja.
 */
const StaticDatoProductFilter: React.FC<StaticDatoProductFilterProps> = props => {
  //console.log('Rendering StaticDatoProductFilter', props)

  const { localePim } = useContext(PageContext)

  // Haetaan minimaaliset tuotedatat, jotta saadaan osassa hauista staattiset linkit SSR sivulle
  //
  const staticProducts = useStaticQuery(graphql`
    query DatoProductFilterStaticData {
      all: allHarviaProduct {
        nodes {
          identifier
          locale
          name # haetaan name, niin sitä käytetään ProductList:ssä ssr linkin sisältönä
          categories # TODO: tässä tulee semi paljon dataa, mahdollisesti optimoidessa pois joskus
          product_family
        }
      }
    }
  `).all.nodes as StaticProduct[]

  let products: ProductFilterProduct[] = []
  const staticLocaleProducts = staticProducts.filter(p => p.locale === localePim)
  const { params } = props
  if (params) {
    Object.keys(params).forEach(key => {
      // value voi olla pilkulla eroteltuja arvoja, jolloin jaetaan se useiksi hakupareiksi
      // esim: {"product_family":"cilindro,forte"}
      const values = params[key].split(",")

      switch (key) {
        case "categories":
          values.forEach(val => {
            products = products.concat(staticLocaleProducts.filter(node => node.categories.includes(val.trim())))
          })
          break

        case "product_family":
          values.forEach(val => {
            products = products.concat(staticLocaleProducts.filter(node => node.product_family === val.trim()))
          })
          break

        case "sku":
          values.forEach(val => {
            products = products.concat(staticLocaleProducts.filter(node => node.identifier === val.trim()))
          })
          break
      }
    })
  }

  return <ProductFilter title={props.title} description={props.description} products={products} />
}

export default StaticDatoProductFilter
